import React, { useEffect, useState } from "react"
import { useInput } from "../hooks/input-hook"
import styled from "styled-components"
import {
  CustomLabel,
  CustomInput,
  CustomTextArea,
  Title,
  PageTitle,
  SubmitButton,
} from "../utils/common-styles"
import colors from "../utils/colors"

const FormWrap = styled.form`
  margin: 0 auto;
  height: 480px;
  width: 350px;
`

const CustomTitle = styled(Title)`
  color: ${colors.GREEN};
`

const ContactForm = () => {
  const { value: name, bind: bindName, reset: resetName } = useInput("")
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput("")
  const {
    value: description,
    bind: bindDescription,
    reset: resetDescription,
  } = useInput("")
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    const form = document.getElementById("contact-form")
    const url = process.env.GATSBY_BASIN_FORM_ENDPOINT
    form.onsubmit = event => {
      event.preventDefault()
      const formData = new FormData(form)
      const xhr = new XMLHttpRequest()

      xhr.open("POST", url, true)
      xhr.onload = e => {
        console.log(xhr)
        const response = JSON.parse(xhr.response)
        if (xhr.status === 200) {
          resetName()
          resetEmail()
          resetDescription()
          setSuccess(true)
        } else {
          console.log("Error response", response)
        }
      }
      xhr.send(formData)
    }
  }, [])

  return (
    <>
      {success ? (
                <PageTitle>
                Tack för ditt meddelande! Vi återkommer snarast
              </PageTitle>

      ) : (
        <div>
                  <PageTitle>
          Fyll i formuläret så återkommer vi inom kort.
        </PageTitle>
        <FormWrap id="contact-form">
          <CustomLabel>Namn</CustomLabel>
          <CustomInput type="text" name="Namn" {...bindName} />
          <CustomLabel>Email</CustomLabel>
          <CustomInput type="email" name="email" {...bindEmail} />
          <CustomLabel>Meddelande</CustomLabel>
          <CustomTextArea
            type="text"
            rows="5"
            name="Meddelande"
            {...bindDescription}
          />
          <SubmitButton type="submit">Skicka</SubmitButton>
        </FormWrap>
        </div>
      )}
    </>
  )
}

export default ContactForm
