import React from "react"

import LayoutPage from "../components/layout-page"
import SEO from "../components/seo"
import { Container } from "../utils/common-styles"
import ogImage from "../images/image_rekryterautvecklare.png"
import ContactForm from "../components/contact-form"

const ContactPage = ({ path }) => {
  return (
    <LayoutPage
      path={path}
      title="Kontakta oss"
      subtitle="Har ni några frågor om sajten eller vill nå oss av någon anledning?"
    >
      <SEO title="Kontakta oss" description="Kontakta oss" image={ogImage} />
      <Container>

        <ContactForm />
      </Container>
    </LayoutPage>
  )
}

export default ContactPage
